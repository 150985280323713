/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../src/assets/css/bootstrap.min.css';

/* @import "~ngx-lightbox/lightbox.css"; */

/* .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: unset !important;
} */

.swiper-button-prev:after {
  content: none !important;
} 
